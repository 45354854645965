<template>
	<h1>Manage Users</h1>
	<div v-if="dataError" class="critical-error">An error has occurred.</div>
	<div v-else-if="users === null" class="loading-indicator">
		<img src="@/assets/images/loading.gif">
	</div>
	<div v-else>
		<div id="user-list-wrapper">
			<div v-if="canCreate" id="create-user-wrapper" class="form">
				<button type="button" @click="createUser">Create New User</button>
				<button v-if="isManagingCustomer && canAssign" type="button" @click="assigningUser = true">Add Existing User</button>
			</div>
			<table v-if="users.length > 0" class="data-table">
				<tr>
					<th>Name</th>
					<th>Email Address</th>
					<th>{{isManagingCustomer ? 'User Type' : 'Access Type'}}</th>
					<th v-if="!managingResidentialCustomer">Profile Name</th>
					<th v-if="canEdit" colspan="4">Actions</th>
				</tr>
				<tr v-for="(user, index) in users" :class="{'inactive-user': !user.active || user.access_type == 'none' || (!this.managingResidentialCustomer && user.profile_name == 'None')}">
					<td>{{`${user.first_name} ${user.last_name}`.trim()}}</td>
					<td>{{user.email_address}}</td>
					<selectable-value-cell v-if="isManagingCustomer" :record-index="index" :selectable-values="validUserTypes()" :selecting-record-index="changeUserTypeIndex" :is-selecting="changingUserType" :allow-selection="canChangeUserType && user.user_type != 'primary'" :is-error="changeUserTypeErrors[index]" @show-selection="display => changeUserTypeIndex = display ? index : null" @save-selection="changeUserType">{{formatUserType(user.user_type)}}</selectable-value-cell>
					<td v-else v-html="formatAccessType(user.access_type, user.customer_access)"></td>
					<td v-if="!managingResidentialCustomer">{{user.profile_name}}</td>
					<td v-if="canEdit" class="edit-cell" @click="editUser(index)" title="Edit User">&#x1F589;</td>
					<td v-if="canEdit" class="action-cell reset-password" @click="resetPassword(index, $event)" title="Reset Password">
						<font-awesome-icon :icon="keyIcon" />
					</td>
					<td v-if="canDelete" class="delete-cell" @click="deleteUser(index)" title="Delete User">X</td>
					<td v-if="isManagingCustomer && canAssign" class="action-cell remove-cell" :class="{removing: user.removing, 'removal-error': user.removalError}" @click="removeUser(index)" title="Remove User">
						<span v-show="!user.removing && !user.removalError">&mdash;</span>
						<span v-show="user.removalError">Error</span>
						<img src="@/assets/images/loading.gif" v-show="user.removing">
					</td>
				</tr>
			</table>
			<pagination v-model="displayPage" :data="users" @page-changed="loadUsers"></pagination>
		</div>
		<delete-user-confirmation v-if="deletingUser" :user="deletingUser" @close="deletingUser = null" @completed="loadUsers(false)"></delete-user-confirmation>
		<reset-password-confirmation v-if="resettingPassword" :user="resettingPassword" @close="resettingPassword = null"></reset-password-confirmation>
		<edit-user-form v-if="editingUser" :user="editedUser" @close="editingUser = false" @completed="loadUsers(false)"></edit-user-form>
		<add-user-to-customer v-if="assigningUser" @close="assigningUser = false" @completed="loadUsers(false)"></add-user-to-customer>
		<modal-view v-if="primaryUserRemovalError" title="Error Removing User" @close="primaryUserRemovalError = false">
			<div class="critical-error">This user is the primary user of the customer and can't be deleted.</div>
		</modal-view>
	</div>
</template>

<script>
	import Pagination from '@/components/Pagination';
	import SelectableValueCell from '@/components/SelectionCells/SelectableValueCell';
	import DeleteUserConfirmation from '@/components/Modals/Users/DeleteUserConfirmation';
	import EditUserForm from '@/components/Modals/Users/EditUserForm';
	import ResetPasswordConfirmation from '@/components/Modals/Users/ResetPasswordConfirmation';
	import AddUserToCustomer from '@/components/Modals/Users/AddUserToCustomer';
	import ModalView from '@/components/ModalView';
	import {mapGetters} from 'vuex';
	
	import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
	import { faKey } from '@fortawesome/free-solid-svg-icons';
	
	export default {
		data() {
			return {
				users: null,
				dataError: false,
				deletingUser: null,
				resettingPassword: null,
				editingUser: false,
				editedUser: null,
				assigningUser: false,
				changeUserTypeIndex: null,
				changingUserType: false,
				changeUserTypeErrors: {},
				primaryUserRemovalError: false,
				displayPage: 1
			}
		},
		computed: {
			keyIcon() {
				return faKey;
			},
			canCreate() { // Used to determine whether the authenticated user has the appropriate permission to create users.
				return this.hasPermission('users', 'create', true);
			},
			canEdit() { // Used to determine whether the authenticated user has the appropriate permission to edit users.
				return this.hasPermission('users', 'edit', true);
			},
			canDelete() { // Used to determine whether the authenticated user has the appropriate permission to delete users.
				return this.hasPermission('users', 'delete', true);
			},
			canAssign() { // Used to determine whether the authenticated user has the appropriate permission to add or remove users from the customer. This requires edit permission for both customers and users, and is only available for internal users.
				return (this.isInternalUser && this.canEdit && this.canEditCustomer);
			},
			canChangeUserType() { // Used to determine whether the authenticated user has the appropriate permission to change the user type of users. This is only valid for corporate customers, and requires the permission to edit users.
				return (this.managingCorporateCustomer && this.canEdit);
			},
			canEditCustomer() { // Used to determine whether the authenticated user has the appropriate permission to edit customers. This is required to add or remove users from the customer.
				return this.hasPermission('customers', 'edit');
			},
			managingResidentialCustomer() { // Used to determine whether the user is currently managing a residential customer.
				return (this.isManagingCustomer && this.managingCustomer.customer_type == 'residential');
			},
			managingCorporateCustomer() { // Used to determine whether the user is currently managing a corporate customer.
				return (this.isManagingCustomer && this.managingCustomer.customer_type == 'corporate');
			},
			...mapGetters(['hasPermission', 'isManagingCustomer', 'managingCustomer', 'isInternalUser'])
		},
		components: {
			Pagination, SelectableValueCell, DeleteUserConfirmation, EditUserForm, ResetPasswordConfirmation, AddUserToCustomer, FontAwesomeIcon, ModalView
		},
		async created() { // When the page is loaded, get the list of users.
			await this.loadUsers();
		},
		methods: {
			async loadUsers(refresh = true) { // Performs the API request to get the list of users.
				// If we're visually refreshing the page (rather than stealthily updating the user list), set the user list to NULL to display the loading indicator.
				if(refresh) {
					this.users = null;
				}
				
				// Perform the API request to get the list of users.
				try {
					const apiEndpoint = this.isManagingCustomer ? `customers/${this.managingCustomer.id}/users` : 'users'; // If the user is managing users for a customer, get the list of users for the given customer, otherwise just get the full list of users.
					const response = await this.HTTP.get(apiEndpoint + `?page=${this.displayPage}`);
					this.users = response.data.data;
				} catch(error) { // If there was an error obtaining the user list, display the generic error message.
					this.dataError = true;
				}
			},
			deleteUser(index) { // Displays the deletion confirmation modal for the user at the given index in the users array.
				this.deletingUser = this.users[index];
			},
			async removeUser(index) { //Removes the user at the given index in the users array from the customer.
				const user = this.users[index];
				if(!user.removing) { // Only continue if this user isn't already in the process of being removed.
					// Mark the user as being removed to prevent the user from initiating multiple API calls, and remove the removal error if it is displayed.
					user.removing = true;
					user.removalError = false;
					
					// Perform the API request to remove the given user from the customer.
					try {
						await this.HTTP.delete(`customers/${this.managingCustomer.id}/users?user-id=${user.id}`);
						this.loadUsers(false);
					} catch(error) { // If there was an error removing the user from the customer, handle it as appropriate depending on the error message.
						if(error.response && error.response.status == 400 || error.response.status == 404) {
							const errorMessage = error.response.data.error;
							switch(errorMessage) {
								case "The given user isn't assigned to the given customer.":
								case "The User ID that was provided doesn't exist.":
									this.loadUsers(false); // These error messages indicate that the user has already been removed from the customer or deleted, so just refresh the user list to show that it is gone.
									return;
								case "The User ID provided must not be the primary user of the given customer.":
								case "The 'new-primary-user' option is required if 'user-id' is the current primary user of the given customer.":
									this.primaryUserRemovalError = true; // These error messages indicate that the user that was being removed is the primary user of the given customer, so display the modal informing the user that this isn't allowed.
									user.removing = false;
									return;
							}
						}
						
						// Any other error is an internal error, so we should just display a generic error message.
						user.removing = false;
						user.removalError = true;
					}
				}
			},
			resetPassword(index, event) { // Displays the reset password confirmation modal for the user at the given index in the users array (if the user is active).
				if(!event.currentTarget.parentElement.classList.contains('inactive-user')) {
					this.resettingPassword = this.users[index];
				}
			},
			editUser(index) { // Displays the edit user modal for the user at the given index in the users array.
				this.editedUser = this.users[index];
				this.editingUser = true;
			},
			createUser() { // Displays the modal for creating a new user.
				this.editedUser = null;
				this.editingUser = true;
			},
			async changeUserType(newUserType) { // Performs the API request to update the user type for the user identified by the changeUserTypeIndex property.
				const user = this.users[this.changeUserTypeIndex];
				if(newUserType != user.user_type) { // Only continue if the selected user type is different from the current user type.
					// Mark the user type change as pending to display the loading indicator, and remove the user type change error if it is displayed.
					this.changingUserType = true;
					this.changeUserTypeErrors[this.changeUserTypeIndex] = false;
					
					try {
						// Perform the API request to update the user type of the given user.
						await this.HTTP.patch(`customers/${this.managingCustomer.id}/users/${user.id}`, {user_type: newUserType});
						if(user == this.users[this.changeUserTypeIndex]) { // Update the status displayed for the user (only if the list of users hasn't been refreshed since this API request was initiated).
							user.user_type = newUserType;
						}
					} catch(error) { // If there was an error updating the user type, display an error message below the user type (only if the list of users hasn't been refreshed since this API request was initiated).
						if(user == this.users[this.changeUserTypeIndex]) {
							this.changeUserTypeErrors[this.changeUserTypeIndex] = true;
						}
					} finally { // Regardless of whether the API request was successful, hide both the user type dropdown and loading indicator.
						this.changingUserType = false;
						this.changeUserTypeIndex = null;
					}
				} else { // If the selected user type is the same as the current user type, simply hide the user type dropdown.
					this.changeUserTypeIndex = null;
				}
			},
			formatUserType(userType) { // Given the user type for a user, returns the user-friendly representation.
				userType = (userType == 'user') ? 'Standard' : this.capitaliseFirstLetter(userType);
				return `${userType} User`;
			},
			formatAccessType(accessType, customerAccess = null) { // Given the access type for a user, returns the user-friendly representation.
				switch(accessType) {
					case 'customer':
						return 'Customer User';
					case 'internal':
						const customerTypes = (customerAccess.length == 1) ? this.capitaliseFirstLetter(customerAccess[0]) : 'All';
						return (customerAccess.length == 0) ? 'Internal User' : `Internal User<br />(${customerTypes} Customers)`;
					case 'none':
						return 'No Access';
				}
			},
			capitaliseFirstLetter(string) { // Returns the input string with the first letter capitalised.
				return string.charAt(0).toUpperCase() + string.slice(1);
			},
			validUserTypes() { // Returns the list of user types formatted for use in the user type selection cells.
				// Set the list of valid values for the underlying API.
				const apiValues = ['authorised', 'user'];
				
				// Create an object to map each underlying API value to the display value.
				const values = {};
				for(const value of apiValues) {
					values[value] = this.formatUserType(value);
				}
				
				return values;
			}
		}
	}
</script>

<style scoped lang="scss">
	#user-list-wrapper {
		width:max-content;
		margin:0 auto;
	}
	
	#create-user-wrapper {
		text-align:right;
		margin-bottom:20px;
		
		button {
			margin-left:20px;
		}
	}
	
	.inactive-user {
		color:var(--inactive-record-color);
		
		.reset-password {
			opacity:0.5;
			cursor:default;
		}
	}
	
	.reset-password {
		background-color:#90D345;
		color:#FFFFFF;
		font-size:1.5rem!important;
	}
	
	.remove-cell {
		background-color:#FF7070;
		color:#FFFFFF;
		font-size:2rem;
		
		img {
			width:20px;
		}
		
		&.removing {
			cursor:auto;
		}
		
		&.removal-error {
			font-size:1rem;
			font-weight:normal;
		}
	}
</style>