<template>
	<modal-view title="Delete User Confirmation" @close="$emit('close')" class="form">
		<div v-if="isCurrentUser" class="critical-error">
			You can't delete your own user.
		</div>
		<div v-else-if="deleting || loadingUser" class="loading-indicator">
			<img src="@/assets/images/loading.gif">
		</div>
		<div v-else-if="completed" class="success-message">
			The user has been deleted successfully.
		</div>
		<div v-else-if="error" class="critical-error">{{error}}</div>
		<div v-else-if="isPrimaryUser" class="critical-error">
			This user is the primary user of a customer and can't be deleted.
		</div>
		<div v-else>
			<p>You are about to delete the user <span id="user-name">{{`${user.first_name} ${user.last_name}`.trim()}}</span>. This cannot be undone. Are you sure you want to continue?</p>
			<button type="button" class="danger" @click="deleteUser">Delete User</button>
			<button type="button" @click="$emit('close')">Cancel</button>
		</div>
	</modal-view>
</template>

<script>
	import { mapState } from 'vuex';
	import ModalView from '@/components/ModalView';
	
	export default {
		props: {
			user: Object
		},
		data() {
			return {
				deleting: false,
				completed: false,
				error: null,
				isPrimaryUser: null
			}
		},
		computed: {
			isCurrentUser() { // Used to determine whether the user that is being deleted is the currently logged in used.
				return (this.user.id == this.currentUser.id);
			},
			loadingUser() { // Used to determine whether the user's customer list is being loaded from the API.
				return (this.isPrimaryUser === null);
			},
			...mapState({
				currentUser: 'user'
			})
		},
		async created() { // When the modal is loaded, check if the given user is the primary user of any customers (this isn't necessary if it's the currently logged in user, since this user can't be deleted anyway).
			if(!this.isCurrentUser) {
				await this.checkPrimaryUser();
			}
		},
		components: {
			ModalView
		},
		methods: {
			async checkPrimaryUser() { // Performs the API requests to check if the given user is the primary user of any customers.
				for(const customerType of ['corporate', 'residential']) {
					try {
						const response = await this.HTTP.get('users/' + this.user.id + '/customers/' + customerType + '?limit=1000');
						const isPrimaryUser = response.data.data.map(customer => customer.user_type).includes('primary');
						if(isPrimaryUser) { // If the user is the primary user of a customer of this customer type, set isPrimaryUser to TRUE, and return from the function since we don't need to check the other customer type.
							this.isPrimaryUser = true;
							return;
						}
					} catch(error) { // If there was an error obtaining the customer list, display an error message.
						this.error = 'An error has occurred.';
						this.isPrimaryUser = false;
						return;
					}
				}
				
				// If we exited the loop without returning form the function, it means that the user isn't the primary user of any customers.
				this.isPrimaryUser = false;
			},
			async deleteUser() { // Performs the API request to delete the given user.
				// Replace the confirmation message with a loading indicator.
				this.deleting = true;
				
				// Perform the API request to delete the given user.
				try {
					await this.HTTP.delete('users/' + this.user.id);
					
					// If the user was deleted successfully, display the success message and instruct the parent component to reload the user list.
					this.completed = true;
					this.$emit('completed');
				} catch(error) { // If there was an error deleting the user, display an error message.
					if(error.response && error.response.status == 400 && error.response.data && error.response.data.error == "The given user is assigned to an agent and can't be deleted.") { // If the error is that the user is assigned to an agent, display the specific error message from the API response.
						this.error = error.response.data.error;
					} else { // For any other error, display a generic error message.
						this.error = 'An error has occurred.';
					}
				} finally { // Regardless of whether the API request was successful, hide the loading indicator.
					this.deleting = false;
				}
			}
		}
	}
</script>

<style scoped lang="scss">
	#user-name {
		font-weight:bold;
	}
	
	button {
		margin-right:20px;
		min-width:100px;
	}
</style>