<template>
	<modal-view title="Reset Password Confirmation" @close="$emit('close')" class="form">
		<div v-if="resetting" class="loading-indicator">
			<img src="@/assets/images/loading.gif">
		</div>
		<div v-else-if="completed">
			<div class="success-message">The user's password has been reset successfully.</div>
			<p>The user's password is displayed below. Please copy and save it, since it can't be recovered after you close this window.</p>
			<div id="password">{{userPassword}}</div>
		</div>
		<div v-else-if="error" class="critical-error">
			An error has occurred.
		</div>
		<div v-else>
			<p>You are about to reset the password for the user <span id="user-name">{{`${user.first_name} ${user.last_name}`.trim()}}</span>. Are you sure you want to continue?</p>
			<button type="button" class="danger" @click="resetPassword">Reset Password</button>
			<button type="button" @click="$emit('close')">Cancel</button>
		</div>
	</modal-view>
</template>

<script>
	import ModalView from '@/components/ModalView';
	import {mapGetters} from 'vuex';
	
	export default {
		props: {
			user: Object
		},
		data() {
			return {
				resetting: false,
				error: false,
				userPassword: null
			}
		},
		computed: {
			completed() { // Used to determine whether the user's password has been reset.
				return (this.userPassword !== null);
			},
			...mapGetters(['isManagingCustomer', 'managingCustomer'])
		},
		components: {
			ModalView
		},
		methods: {
			async resetPassword() {
				// Replace the confirmation message with a loading indicator.
				this.resetting = true;
				
				// Perform the API request to reset the password of the given user.
				try {
					const apiEndpoint = this.isManagingCustomer ? `customers/${this.managingCustomer.id}/users/` : 'users/'; // If the user is managing users for a customer, we need to use the customer users endpoint, as customer users don't have access to the main users endpoint.
					const response = await this.HTTP.put(apiEndpoint + this.user.id + '/reset-password');
					
					// If the user's password was reset successfully, display the new password and instruct the parent component to relaod the user list.
					this.userPassword = response.data.data.password;
				} catch(error) { // If there was an error resetting the user's password, display an error message.
					this.error = true;
				} finally { // Regardless of whether the API request was successful, hide the loading indicator.
					this.resetting = false;
				}
			}
		}
	}
</script>

<style scoped lang="scss">
	#user-name {
		font-weight:bold;
	}
	
	button {
		margin-right:20px;
		min-width:100px;
	}
	
	#password {
		background-color:var(--highlight-color-light);
		padding:30px;
		text-align:center;
		font-size:3rem;
	}
</style>