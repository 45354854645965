<template>
	<div v-if="loadingAvailablePermissions" class="loading-indicator">
		<img src="@/assets/images/loading.gif">
	</div>
	<div v-else-if="selectedPermissionsSet" id="permissions-list">
		<fieldset v-for="(modulePermissions, module) in availablePermissions">
			<legend>{{kebabCaseToTitleCase(module)}}</legend>
			<div class="checkboxes">
				<label v-for="permission in modulePermissions" :title="generatePermissionDescription(permission)" :class="{'important-permission': (module == 'users' && permission.permission_name == 'manage-internal')}">
					<input type="checkbox" :id="`permission--${module}--${permission.permission_name}`" :value="permission.permission_name" :checked="selectedPermissions[module] && selectedPermissions[module].includes(permission.permission_name)" @change="setPermissionValue(module, permission.permission_name, $event)" />
					{{kebabCaseToTitleCase(permission.permission_name)}}
				</label>
			</div>
		</fieldset>
	</div>
</template>

<script>
	import { mapState, mapGetters, mapActions } from 'vuex';
	
	export default {
		props: {
			permissions: {
				type: Object,
				default: {}
			},
			includeInternalPermissions: {
				type: Boolean,
				default: true
			}
		},
		data() {
			return {
				availablePermissions: null,
				availablePermissionsCached: {},
				selectedPermissions: null
			}
		},
		computed: {
			loadingAvailablePermissions() { // Used to determine whether the list of available user permissions are being loaded from the API.
				return (this.availablePermissions === null);
			},
			selectedPermissionsSet() { // Used to determine whether the list of selected permissions is available.
				return (this.selectedPermissions !== null);
			},
			canManageInternal() { // Used to determine whether the authenticated user has the appropriate permission to manage internal users.
				return this.hasPermission('users', 'manage-internal')
			},
			...mapGetters(['hasPermission'])
		},
		async created() { // When the component is loaded, populate the form with the list of available permissions.
			this.setSelectedPermissions(); // Since we're managing the binding to the permissions property manually, we don't want the state of the checkboxes to automatically update to reflect the underlying data, so we need to make a copy of the selected permissions to use for the binding in the "checked" property.
			await this.getAvailableUserPermissions();
		},
		methods: {
			async getAvailableUserPermissions() { // Performs the API request to get the list of available user permissions.
				// Set the list of available permissions to NULL to display the loading indicator.
				this.availablePermissions = null;
				
				// Perform the API request to get the list of available user permissions.
				try {
					const permissionsType = this.includeInternalPermissions ? 'internal' : 'customer';
					if(this.availablePermissionsCached.hasOwnProperty(permissionsType)) { // If the list of available permissions for this permission type has already been cached, used the cached list.
						this.availablePermissions = this.availablePermissionsCached[permissionsType];
					} else { // If the list of available permissions for this permission type hasn't been cached yet, load the data from the API.
						const queryString = this.canManageInternal ? `?access-level=${permissionsType}` : ''; // Sets the access level in the query string, if the user has access to set this parameter.
						const response = await this.HTTP.get(`user-profiles/permissions${queryString}`);
						this.availablePermissions = response.data.data;
						this.availablePermissionsCached[permissionsType] = this.availablePermissions;
					}
					
					// Notify the parent component that the list of available permissions was loaded, and toggle which checkboxes are enabled or disabled based on their dependencies.
					this.$emit('available-permissions-loaded');
					this.checkPermissionDependencies();
				} catch(error) { // If there was an error obtaining the list of available permissions, notify the parent component.
					this.$emit('data-error');
				}
			},
			setPermissionValue(module, permission, event) { // Called when a checkbox is toggled to set the permission in the permissions property. This is required since the list of permissions is dynamic, so a v-model can't be used.
				// Get the existing permissions for the given module, and check the new and previous values for the given permissions.
				let modulePermissions = this.permissions[module] ?? []; // If there are no existing permissions for this module, create an empty array so that we can add this permission.
				const enabled = (!event.target.disabled && event.target.checked); // This method is also called to update the value when a checkbox is enabled or disabled due to a dependency, so whether or not to include the permission actually depends on whether the checkbox is both enabled and checked.
				const previouslyEnabled = modulePermissions.includes(permission);
				
				// Update the permissions for the given module based on whether the checkbox was checked or unchecked.
				if(enabled && !previouslyEnabled) {
					modulePermissions.push(permission);
				} else if(!enabled && previouslyEnabled) {
					const index = modulePermissions.indexOf(permission);
					modulePermissions.splice(index, 1);
				}
				
				// Toggle the enabled state of the checkboxes for any permissions that are dependant on this permission, and update the permissions property with the new permissions for this module.
				this.toggleDependantPermissions(module, permission, enabled);
				this.permissions[module] = modulePermissions;
				this.$emit('permissions-changed'); // Notifies the parent component that the selected permissions have changed.
			},
			toggleDependantPermissions(module, permission, enabled, cascading = true) { // Toggles whether the checkboxes of the permissions that are dependant on the given permission are enabled, based on whether the given permission is checked.
				for(const modulePermission of this.availablePermissions[module]) {
					if(modulePermission.prerequisite == permission) { // If this permission is dependant on the given permission, toggle its enabled state based on whether the given permission is checked.
						const checkbox = document.getElementById(`permission--${module}--${modulePermission.permission_name}`);
						checkbox.disabled = !enabled;
						
						// Trigger a change event on the affected checkbox to update the state of the permission in the selected permissions, and to toggle the dependencies of this checkbox as well.
						if(cascading) {
							checkbox.dispatchEvent(new Event('change'));
						}
					}
				}
			},
			checkPermissionDependencies() { // Toggles which checkboxes are enabled or disabled based on their dependencies.
				setTimeout(() => {
					for(const module in this.availablePermissions) {
						// Get the list of available permissions for this module, and the list of permissions for this module that are pre-selected.
						const permissions = this.availablePermissions[module];
						const selectedPermissions = this.permissions[module] ?? []; // If there are no selected permissions for this module, use an empty array to standardise the interaction with variable.
						
						// Loop through each of the available permissions for this module, and toggle the enabled state of its dependencies.
						for(const permission of permissions) {
							const enabled = selectedPermissions.includes(permission.permission_name);
							this.toggleDependantPermissions(module, permission.permission_name, enabled, false);
						}
					}
				}, 2); // The 2ms delay is required because this method is called when the list of available permissions is loaded from the API, which is just before the interface is actually updated. It's also potentially just after the list of selected permissions is set, which has its own delay of 1ms.
			},
			generatePermissionDescription(permission) { // Generates the description tooltip for the given permission.
				let description = permission.description;
				if(permission.description !== null && permission.prerequisite !== null) { // If the given permission has a prerequisite, add it to the description.
					description += ' Requires the ' + this.kebabCaseToTitleCase(permission.prerequisite) + ' permission.';
				}
				
				return description;
			},
			setSelectedPermissions() { // Updates the form to display the selected permissions based on the "permissions" property.
				this.selectedPermissions = null; // Temporarily hides the form, forcing it to re-render after we set the new permissions. This prevents a bug where the updated permissions aren't always reflected.
				setTimeout(() => {
					this.selectedPermissions = JSON.parse(JSON.stringify(this.permissions)); // Since we're managing the binding to the permissions property manually, we don't want the state of the checkboxes to automatically update to reflect the underlying data, so we need to make a copy of the selected permissions to use for the binding in the "checked" property.
				}, 1); // The 1ms delay is required to ensure that the form has re-rendered after we cleared the permissions.
			},
			kebabCaseToTitleCase(value) { // Returns the input string with dashes replaced with spaces.
				return value.replace('-', ' ').replace('api', 'API'); // Some of the strings being converted also include the term "API", which needs to be fully capitalised instead of using title case.
			}
		},
		watch: {
			permissions(value) { // When the permissions passed in through the "permissions" property are updated, update the permissions displayed in the form.
				this.setSelectedPermissions();
				this.checkPermissionDependencies();
			},
			async includeInternalPermissions(value) { // When the property indicating whether to include internal permissions in the interface changes, update the list of permissions that are displayed.
				await this.getAvailableUserPermissions();
			}
		}
	}
</script>

<style scoped lang="scss">
	#permissions-list legend, #permissions-list label {
		text-transform:capitalize;
		white-space:nowrap;
	}
	
	fieldset {
		width:100%;
		padding-bottom:0;
		
		.checkboxes {
			margin-bottom:0;
		}
		
		label {
			display:inline-block;
			padding-bottom:10px;
			
			&.important-permission {
				color:#4D842D;
			}
		}
	}
</style>