<template>
	<modal-view :title="creatingUser ? 'Create User' : 'Edit User'" @close="$emit('close')">
		<div v-if="savingUser || loadingUser" class="loading-indicator">
			<img src="@/assets/images/loading.gif">
		</div>
		<div v-else-if="userSaved">
			<div class="success-message">User {{creatingUser ? 'Created' : 'Updated'}} Successfully</div>
			<div v-if="userPassword">
				<p>The user's password is displayed below. Please copy and save it, since it can't be recovered after you close this window.</p>
				<div id="password">{{userPassword}}</div>
			</div>
		</div>
		<div v-else-if="dataError" class="critical-error">
			An error has occurred.
		</div>
		<div v-else>
			<form class="data-form" @submit.prevent="saveUser">
				<div>
					<label for="user-title">Title</label>
					<span class="required">*</span>
					<select id="user-title" v-model="title" @change="errorMessage = null">
						<option value="" disabled v-if="title == ''" v-once>Select...</option>
						<option>Mr</option>
						<option>Mrs</option>
						<option>Miss</option>
						<option>Ms</option>
					</select>
				</div>
				<div>
					<label for="first-name">First Name</label>
					<span class="required">*</span>
					<input type="text" id="first-name" v-model="firstName" @input="errorMessage = null" maxlength="255" />
				</div>
				<div>
					<label for="last-name">Last Name</label>
					<span class="required">*</span>
					<input type="text" id="last-name" v-model="lastName" @input="errorMessage = null" maxlength="255" />
				</div>
				<div>
					<label for="email-address">Email Address</label>
					<span class="required">*</span>
					<input type="text" id="email-address" v-model="emailAddress" @input="errorMessage = null" maxlength="255" />
				</div>
				<div>
					<label for="user-phone-number">Phone Number</label>
					<span class="required" v-if="isPrimaryUser">*</span>
					<phone-input id="user-phone-number" v-model="phoneNumber" @input="errorMessage = null"></phone-input>
				</div>
				<div v-if="showUserTypeField">
					<label for="user-type">User Type</label>
					<span class="required">*</span>
					<select id="user-type" v-model="userType" @change="errorMessage = null">
						<option value="user">Standard User</option>
						<option value="authorised">Authorised User</option>
					</select>
				</div>
				<div v-if="canManageInternal && creatingUser">
					<label for="access-type">Access Type</label>
					<span class="required">*</span>
					<select id="access-type" v-model="accessType" @change="errorMessage = null">
						<option value="customer">Customer User</option>
						<option value="internal">Internal User</option>
					</select>
				</div>
				<div v-if="canManageInternal" v-show="accessType == 'internal'" class="checkboxes">
					<label v-if="!creatingUser" class="checkboxes-label">Access Type</label>
					<label><input type="checkbox" value="corporate" v-model="customerAccessTypes" @change="errorMessage = null" /> Corporate</label>
					<label><input type="checkbox" value="residential" v-model="customerAccessTypes" @change="errorMessage = null" /> Residential</label>
				</div>
				<div v-if="showPermissionsOptions">
					<label for="user-profile">User Profile</label>
					<span class="required">*</span>
					<div v-if="loadingUserProfiles" class="loading-indicator">
						<img src="@/assets/images/loading.gif">
					</div>
					<v-select v-else id="user-profile" v-model="profileIndex" :options="formatProfileListForDropdown()" :reduce="profile => profile.index" :selectable="profile => (profile.index !== null)" :clearable="false">
						<template v-slot:no-options>No matching user profiles found.</template>
					</v-select>
				</div>
				<div v-if="showPermissionsOptions && !loadingUserProfiles" id="user-permissions">
					<h3>Permissions</h3>
					<user-permissions-form :permissions="permissions" :include-internal-permissions="this.accessType == 'internal'" @permissions-changed="profileIndex = null" @available-permissions-loaded="loadingAvailablePermissions = false" @data-error="this.dataError = true"></user-permissions-form>
				</div>
				<div class="button-wrapper">
					<button type="submit">{{creatingUser ? 'Create' : 'Save'}} User</button>
				</div>
			</form>
			<div class="critical-error">{{errorMessage}}</div>
		</div>
	</modal-view>
</template>

<script>
	import ModalView from '@/components/ModalView';
	import PhoneInput from '@/components/PhoneNumbers/PhoneInput';
	import UserPermissionsForm from '@/components/UserPermissionsForm';
	import { mapState, mapGetters, mapActions } from 'vuex';
	import vSelect from 'vue-select';
	
	export default {
		props: {
			user: Object
		},
		data() {
			return {
				title: '',
				firstName: '',
				lastName: '',
				emailAddress: '',
				phoneNumber: '',
				userType: 'user',
				accessType: 'customer',
				customerAccessTypes: [],
				profileId: null,
				profileIndex: null,
				permissions: {},
				userProfiles: null,
				isPrimaryUser: false,
				isFirstUser: null,
				errorMessage: null,
				loadingAvailablePermissions: true,
				userSaved: false,
				savingUser: false,
				userPassword: null,
				dataError: false
			}
		},
		computed: {
			creatingUser() { // Used to determine whether a new user is being created, or an existing user is being updated.
				return (this.user === null);
			},
			loadingUser() { // Used to determine whether the updated user details are being loaded from the API.
				return (this.user && this.user.loading);
			},
			loadingUserProfiles() { // Used to determine whether the list of user profiles are being loaded from the API.
				return (this.userProfiles === null);
			},
			canManageInternal() { // Used to determine whether the authenticated user has the appropriate permission to manage internal users. This is also only valid when not managing a customer.
				return  (!this.isManagingCustomer && this.hasPermission('users', 'manage-internal'));
			},
			apiEndpoint() { // Determines the API endpoint to use depending on whether the user is currently managing a customer.
				return this.isManagingCustomer ? `customers/${this.managingCustomer.id}/users/` : 'users/';
			},
			showPermissionsOptions() { // Used to determine whether to display the user permissions options. These aren't displayed when managing a residential customer.
				return (!this.isManagingCustomer || this.managingCustomer.customer_type != 'residential');
			},
			managingCorporateCustomer() { // Used to determine whether the user is currently managing a corporate customer.
				return (this.isManagingCustomer && this.managingCustomer.customer_type == 'corporate');
			},
			showUserTypeField() { // Used to determine whether to display the User Type field in the form. This is only displayed when managing a corporate customer (since this can't be changed for residential customers, and isn't relevant when not managing a customer), and when managing the non-primary user, since the user type of the primary user can't be changed. It's also not displayed when creating the first user for a customer, since this user is always created as the primary user.
				return (this.managingCorporateCustomer && this.isFirstUser === false && this.userType != 'primary');
			},
			...mapState({
				currentUser: 'user'
			}),
			...mapGetters(['hasPermission', 'isManagingCustomer', 'managingCustomer'])
		},
		components: {
			ModalView, PhoneInput, UserPermissionsForm, vSelect
		},
		async created() { // When the modal is loaded, check if we are editing an existing user, and if so, populate the form with the existing user details.
			if(!this.creatingUser) {
				await this.getUserDetails();
			} else if(this.isManagingCustomer) { // If a new user is being created for a specific customer, check whether it is the first user for the given customer.
				await this.checkFirstUser();
			}
			
			// Get the list of user profiles to display in the dropdown menu.
			await this.getUserProfiles();
		},
		methods: {
			async getUserDetails() { // Performs the API request to get the user details for the given user.
				// Display a loading indicator until the user details are updated.
				this.user.loading = true;
				
				// Perform the API request to get the user details.
				try {
					const response = await this.HTTP.get(this.apiEndpoint + this.user.id);
					const user = response.data.data;
					
					this.title = user.title;
					this.firstName = user.first_name;
					this.lastName = user.last_name;
					this.emailAddress = user.email_address;
					this.phoneNumber = user.phone_number ?? ''; // The PhoneInput component doesn't work with NULL values, so we convert it to an empty string instead.
					this.accessType = user.access_type;
					if(this.accessType == 'internal') {
						this.customerAccessTypes = user.customer_access;
					}
					if(this.showUserTypeField) {
						this.userType = user.user_type;
					}
					
					// Get the user permissions for the given user, and check if the user is the primary user of a residential customer.
					await Promise.all([this.getUserPermissions(), this.checkPrimaryUser()]);
				} catch(error) { // If there was an error obtaining the user details, display the generic error message.
					this.dataError = true;
				} finally { // Regardless of whether the API request was successful, hide the loading indicator.
					this.user.loading = false;
				}
			},
			async getUserPermissions() { // Performs the API request to obtain the permissions data for the given user.
				if(this.showPermissionsOptions) { // Only continue if the permissions options actually need to be displayed in the interface.
					let response = await this.HTTP.get(this.apiEndpoint + this.user.id + '/permissions');
					response = response.data.data;
					
					// Set the Profile ID from the response data. If the value is NULL, set the permissions data from the user's custom permissions instead.
					this.profileId = response.profile_id;
					if(this.profileId === null) {
						this.setPermissions(response.permissions);
					}
				}
			},
			async checkPrimaryUser() { // Performs the API request to check if the given user is the primary user of a residential customer.
				try {
					await this.HTTP.get('users/' + this.user.id + '/customers/residential/primary/internal');
					this.isPrimaryUser = true; // If this API request returns a successful response, it means that the user is the primary user of a residential customer.
				} catch(error) { // If the response is a 404 error indicating that the user isn't the primary user of a residential customer, simpy ignore it. Otherwise, re-throw the error to be handled by the calling method.
					if(!(error.response && error.response.status == 404 && error.response.data && error.response.data.error == "The given user isn't the primary user of a residential customer.")) {
						throw error;
					}
				}
			},
			async getUserProfiles() { // Performs the API request to get the list of user profiles.
				if(this.showPermissionsOptions) { // Only continue if the permissions options actually need to be displayed in the interface.
					try {
						const response = await this.HTTP.get('user-profiles?limit=1000');
						this.userProfiles = response.data.data;
						
						// If the user's Profile ID is set, find the profile with the matching ID and update the value selected in the dropdown menu to match that profile.
						if(this.profileId !== null) {
							const profileIndex = this.userProfiles.findIndex(profile => profile.id == this.profileId);
							if(profileIndex != -1) {
								this.profileIndex = profileIndex;
							}
						}
					} catch(error) { // If there was an error obtaining the list of user profiles, display the generic error message.
						this.dataError = true;
					}
				}
			},
			async checkFirstUser() { // Performs the API request to get the list of users that are already assigned to the given customer. This is used to determine whether the user being created is the first user for the customer.
				try {
					const response = await this.HTTP.get(`customers/${this.managingCustomer.id}/users?limit=1000`);
					this.isFirstUser = (response.data.data.length == 0);
				} catch(error) { // If there was an error obtaining the list of users, display the generic error message.
					this.dataError = true;
				}
			},
			async saveUser() { // Performs the API request to create or update the given user.
				if(this.validateForm()) { // Only attempt to perform the API request if the form has been filled out correctly.
					try {
						// Replace the edit user form with a loading indicator.
						this.savingUser = true;
						
						// Set the data to create or update the user.
						const data = {title: this.title, first_name: this.firstName, last_name: this.lastName, email_address: this.emailAddress, phone_number: this.phoneNumber};
						if(this.showUserTypeField) {
							data.user_type = this.userType;
						}
						if(this.canManageInternal) { // The access type of the user is only allowed if the authenticated user has the appropriate permission to create internal users, otherwise it just defaults to 'customer'.
							data.access_type = this.accessType
						}
						if(this.accessType == 'internal') { // For internal users, we also need to set the customer access types for the user.
							data.customer_access = this.customerAccessTypes;
						}
						
						// Perform the API requests to create or update the given user, and then save their user permissions.
						const userId = (this.creatingUser) ? await this.createUser(data) : await this.updateUser(data);
						await this.saveUserPermissions(userId);
						
						// If the user that was edited is the current user, update the user details displayed in the interface.
						if(!this.creatingUser && this.user.id == this.currentUser.id) {
							this.updateUserDetails();
						}
						
						// If the user was updated successfully, display the success message and instruct the parent component to reload the user list.
						this.userSaved = true;
						this.$emit('completed');
					} catch(error) { // If there was an error saving the user, display an error message below the edit user form.
						this.setErrorMessage(error);
					} finally { // Regardless of whether the API request was successful, hide the loading indicator and re-display the form.
						this.savingUser = false;
					}
				}
			},
			async createUser(data) { // Performs the API request to create a user with the given data.
				const apiEndpoint = this.apiEndpoint.replace(/\/$/, ''); // Since there is no argument for this endpoint, we need to remove the trailing slash.
				let response = await this.HTTP.post(apiEndpoint, data);
				response = response.data.data;
				
				this.userPassword = response.password; // When a new user is created, we need to display the user's password.
				return response.user_id; // Returns the User ID of the user that was created, to be passed to the saveUserPermissions() method.
			},
			async updateUser(data) { // Performs the API request to update the given user with the given data.
				await this.HTTP.put(this.apiEndpoint + this.user.id, data);
				return this.user.id; // Returns the User ID of the user that was edited, to be passed to the saveUserPermissions() method.
			},
			async saveUserPermissions(userId) { // Performs the API request to save the user permissions for the given user.
				if(this.showPermissionsOptions) { // Only continue if the permissions options are actually displayed in the interface.
					// Set the data for the API request, either to the Profile ID if a profile is selected, or to the custom permissions if no profile is selected.
					const data = {};
					if(this.profileIndex !== null) {
						data.profile_id = this.userProfiles[this.profileIndex].id;
					} else {
						data.permissions = this.permissions;
					}
					
					// Perform the API request to save the user permissions for the given user.
					await this.HTTP.put(this.apiEndpoint + userId + '/permissions', data);
				}
			},
			validateForm() { // Validates the data provided in the form.
				// Check if the list of available permissions has loaded before attempting to save the user (this is only applicable if the permissions options are actually displayed in the interface).
				if(this.showPermissionsOptions && this.loadingAvailablePermissions) {
					this.errorMessage = 'Waiting for the list of available permissions to load.';
					return false;
				}
				
				// Set the list of required fields.
				const requiredFields = {'title': this.title, 'first name': this.firstName, 'last name': this.lastName, 'email address': this.emailAddress};
				if(this.isPrimaryUser) { // If the user is the primary user of a residential customer, the phone number field is also required.
					requiredFields['phone number'] = this.phoneNumber;
				}
				
				// Validate that all required fields in the edit form have been filled in.
				for(const field in requiredFields) {
					const value = requiredFields[field];
					if(value == '' && value !== false) { // For the phone number field, if the value entered is invalid, it's returned as FALSE. This is validated separately, so we want to ignore it here.
						this.errorMessage = `The user's ${field} is required.`;
						return false;
					}
				}
				
				// Validate that the phone number field is valid.
				if(this.phoneNumber === false) {
					this.errorMessage = "The phone number provided is invalid.";
					return false;
				}
				
				return true;
			},
			setErrorMessage(error) { // Sets the error message below the edit user form, based on the error returned by the API request.
				if(error.response && error.response.status == 400) { // A 400 status code indicates a validation error, so we should display the error returned by the API. However, for some error messages, we need to replace them with a more human friendly error message.
					const errorMessage = error.response.data.error;
					switch(errorMessage) {
						case "The 'phone_number' field is required for users that are the primary user of a residential customer.":
							this.errorMessage = "The user's phone number is required.";
							this.isPrimaryUser = true; // This also sets the phone number field as required for future client-side validation.
							break;
						case "The 'email_address' field must be a valid email address.":
							this.errorMessage = 'The email address provided is invalid.';
							break;
						default:
							this.errorMessage = errorMessage;
					}
				} else { // Any other error is an internal error, so we should just display a generic error message.
					this.errorMessage = 'An error has occurred.';
				}
			},
			formatProfileListForDropdown() { // Returns the list of user profiles formatted for use in the profile list dropdown menu.
				const profileList = this.userProfiles.map(function(profile, index){
					return {index: index, label: profile.profile_name}
				});
				profileList.unshift({index: null, label: 'Custom'});
				return profileList;
			},
			setPermissions(permissions) { // Sets the selected permissions to the given data.
				this.permissions = !Array.isArray(permissions) ? permissions : {}; // An empty permissions list is returned from the API as an empty array, so we need to convert this to an empty object instead.
			},
			...mapActions([
				'updateUserDetails'
			])
		},
		watch: {
			profileIndex(value) { // When the user profile is changed, update the displayed user permissions to match that profile.
				this.errorMessage = null; // Since this method is called when the value of a dropdown menu is changed, we need to clear any error messages, just like we do for any other field.
				if(value !== null) { // If "Custom" is selected, leave the permissions data as is.
					const permissions = JSON.parse(JSON.stringify(this.userProfiles[value].permissions)); // We don't want to update the permissions in the actual profile, in case the user changes the profile selection back we need to be able to return to the original permissions, so we need to set the selected permissions to a copy of the profile instead.
					this.setPermissions(permissions);
				}
			},
			accessType(value) { // When the user's access type is changed, clear the list of selected permissions, since some of the selected permissions may no longer be valid.
				this.loadingAvailablePermissions = true;
				if(this.profileIndex === null) { // This is only necessary for custom permissions, not if a profile is selected.
					this.permissions = {};
				}
			}
		}
	}
</script>

<style scoped lang="scss">
	.data-form {
		width:50%;
		
		input, select {
			width:100%;
		}
	}
	
	#user-permissions {
		margin-top:50px;
		margin-left:-50%;
		margin-right:-50%;
		
		h3 {
			text-align:center;
		}
	}
	
	#password {
		background-color:var(--highlight-color-light);
		padding:30px;
		text-align:center;
		font-size:3rem;
	}
</style>