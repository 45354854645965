<template>
	<modal-view title="Add User" @close="$emit('close')">
		<div v-if="dataError" class="critical-error">An error has occurred.</div>
		<div v-else-if="saving || loadingUserList" class="loading-indicator">
			<img src="@/assets/images/loading.gif">
		</div>
		<div v-else-if="completed" class="success-message">The selected user has successfully been assigned to the customer.</div>
		<div v-else>
			<form class="data-form" @submit.prevent="assignUser">
				<p>Please select a user to assign to the customer:</p>
				<div>
					<label for="user-id">User</label>
					<span class="required">*</span>
					<v-select id="user-d" v-model="userId" :options="formatUserListForDropdown()" :reduce="user => user.id" :selectable="user => (user.id !== null)" :clearable="false">
						<template v-slot:no-options>No matching users found.</template>
					</v-select>
				</div>
				<div v-if="showUserTypeField">
					<label for="user-type">User Type</label>
					<span class="required">*</span>
					<select id="user-type" v-model="userType" @change="errorMessage = null">
						<option value="user">Standard User</option>
						<option value="authorised">Authorised User</option>
					</select>
				</div>
				<div class="button-wrapper">
					<button type="submit">Assign</button>
				</div>
			</form>
			<div class="critical-error">{{errorMessage}}</div>
		</div>
	</modal-view>
</template>

<script>
	import ModalView from '@/components/ModalView';
	import vSelect from 'vue-select';
	import {mapGetters} from 'vuex';
	
	export default {
		data() {
			return {
				userList: null,
				existingUsers: null,
				userId: null,
				userType: 'user',
				saving: false,
				completed: false,
				errorMessage: null,
				dataError: false
			}
		},
		computed: {
			loadingUserList() { // Used to determine whether the list of users is being loaded from the API.
				return (this.userList === null || this.existingUsers === null);
			},
			managingCorporateCustomer() { // Used to determine whether the user is currently managing a corporate customer.
				return (this.isManagingCustomer && this.managingCustomer.customer_type == 'corporate');
			},
			showUserTypeField() { // Used to determine whether to display the User Type field in the form. This is only displayed when managing a corporate customer (since this can't be changed for residential customers, and isn't relevant when not managing a customer). It's also not displayed when assigning the first user to a customer, since this user is always set as the primary user.
				return (this.managingCorporateCustomer && !this.isFirstUser);
			},
			isFirstUser() { // Used to determine whether the user being assigned is the first user for the given customer.
				return (this.existingUsers.length == 0);
			},
			canManageInternal() { // Used to determine whether the authenticated user has the appropriate permission to manage internal users.
				return this.hasPermission('users', 'manage-internal');
			},
			...mapGetters(['isManagingCustomer', 'managingCustomer', 'hasPermission'])
		},
		components: {
			ModalView, vSelect
		},
		async created() { // When the modal is loaded, perform the API requests to get the list of available users, and the list of users that are already assigned to the given customer.
			await this.loadAvailableUsers();
		},
		methods: {
			async loadAvailableUsers() { // Performs the API requests to both both the full list of available users, and the list of users that are already assigned to the given customer.
				try {
					await Promise.all([this.loadUserList(), this.loadExistingUsers()]);
				} catch(error) { // If there was an error obtaining either of the user lists, display the generic error message.
					this.dataError = true;
				}
			},
			loadUserList() { // Performs the API request to get the list of users.
				const queryString = this.canManageInternal ? '?access-type=customer' : ''; // Sets query string argument to only return customer users, if the user has access to set this parameter.
				return this.HTTP.get('users' + queryString).then(response => this.userList = response.data.data);
			},
			loadExistingUsers() { // Performs the API request to get the list of users that are already assigned to the given customer.
				return this.HTTP.get(`customers/${this.managingCustomer.id}/users?limit=1000`).then(response => this.existingUsers = response.data.data.map(user => user.id));
			},
			async assignUser() { // Performs the API request to assign the given user to the given customer.
				if(this.validateForm()) { // Only attempt to perform the API request if the form has been filled out correctly.
					try {
						// Replace the user selection form with a loading indicator.
						this.saving = true;
						
						// Set the data for the API request.
						const data = {user_id: this.userId};
						if(this.showUserTypeField) {
							data.user_type = this.userType;
						}
						
						// Perform the API request to assign the given user to the given customer.
						await this.HTTP.patch(`customers/${this.managingCustomer.id}/users`, data);
						
						// If the user was assigned to the customer successfully, display the success message and instruct the parent component to reload the user list.
						this.completed = true;
						this.$emit('completed');
					} catch(error) { // If there was an error assigning the user to the customer, display an error message below the form.
						this.errorMessage = 'An error has occurred';
					} finally { // Regardless of whether the API request was successful, hide the loading indicator and re-display the form.
						this.saving = false;
					}
				}
			},
			validateForm() { // Validates that a user was selected to add to the customer.
				if(this.userId === null) {
					this.errorMessage = 'No user was selected.';
					return false;
				}
				
				return true;
			},
			formatUserListForDropdown() { // Returns the list of users formatted for use in the user list dropdown menu (this also excludes the existing users for the given customer).
				const userList = this.userList.filter(user => !this.existingUsers.includes(user.id)).map(user => {
					return {id: user.id, label: `${user.first_name} ${user.last_name}`.trim()}
				});
				userList.unshift({id: null, label: 'Select...'});
				return userList;
			}
		},
		watch: {
			userId(value) { // When a user is selected to assign to the customer, we need to clear any error messages, just like we do for any other field.
				this.errorMessage = null;
			}
		}
	}
</script>

<style scoped lang="scss">
.data-form {
	width:50%;
	
	select {
		width:100%;
	}
}
</style>